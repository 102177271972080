import React, { Suspense, useEffect, useState } from 'react'
import dynamic from 'next/dynamic';
import { getJanrainCaptureProfileDataFromLocalStorage, deleteJanrainCaptureProfileDataFromLocalStorage, getJanrainCaptureTokenExpiresFromLocalStorage } from '../Janrain/helpers/localstorage.helpers';
import { isObjectEmpty } from '../../adapters/helpers/Utils';
import PropTypes from 'prop-types'
import useJanrainGlobalState from '../Janrain/Janrain/janrainForms/useJanrainGlobalState';
import { redirectTo } from '../Janrain/helpers/redirect.helpers';
import { triggerLogoutEvent } from '../Janrain/helpers/localstorage.helpers';
import Loader from '../../adapters/helpers/Loader';
const ObLink = dynamic(() => import('../ObLink/ObLink'));
const Image = dynamic(() => import('../Image/Image'));

export default function LoginNavigationBlock({ document }) {
    const loginNavigationLabel = document?.fields?.loginNavigationLabel?.fields?.text;
    const loginNavigationLink = document?.fields?.loginNavigationLink?.fields?.slug;
    const registerNavigationLabel = document?.fields?.registerNavigationLabel?.fields?.text;
    const registerNavigationLink = document?.fields?.registerNavigationLink?.fields?.slug;
    const logoutNavigationLabel = document?.fields?.logoutNavigationLabel?.fields?.text;
    const welcomeNavigationLabel = document?.fields?.welcomeNavigationLabel?.fields?.text;
    const welcomeNavigationLink = document?.fields?.welcomeNavigationLink?.fields?.slug;
    const adchoices = document?.fields?.adchoices;
    const externalRegisterLink = document?.fields?.externalRegisterLink;
    const [userData, setUserData] = useState({});
    const [firstName, setFirstName] = useState('');
    const [globalState] = useJanrainGlobalState();

    useEffect(() => {
        const profileData = JSON.parse(getJanrainCaptureProfileDataFromLocalStorage());
        const tokenExpiresData = getJanrainCaptureTokenExpiresFromLocalStorage();
        let tokenExpiryDate = new Date();
        if (tokenExpiresData) {
            tokenExpiryDate = new Date(tokenExpiresData);
        }
        if (!isObjectEmpty(profileData)) {
            let now = new Date();
            if (now < tokenExpiryDate) {
                setUserData(profileData);
                setFirstName(profileData?.givenName);
            } else {
                // account expired, we delete all janrain data in localStorage,
                // this allow us to avoid initializing the widget in every page of the master site.
                deleteJanrainCaptureProfileDataFromLocalStorage();
            }
        }
    }, []);

    useEffect(() => {
        if (globalState?.givenName) {
            setFirstName(globalState?.givenName)
        }
    }, [globalState]);

    const logout = () => {
        if (window?.janrain?.capture?.ui?.endCaptureSession) {
            window.janrain.capture.ui.endCaptureSession();
            redirectTo(process.env.JANRAIN_LOGIN_PATH);
            triggerLogoutEvent();
        } else {
            deleteJanrainCaptureProfileDataFromLocalStorage();
            window.location.reload();
            triggerLogoutEvent();
        }
    };

    return (
        <Suspense fallback={<Loader />}>
            <div className='ob-login-navigation'>
                {adchoices &&
                    <ObLink tag={'a'} href={adchoices.fields?.url} className='ob-login-navigation__adchoices' isExternalLink={true} isRelNofollow={true}>
                        <>
                            <span>{adchoices?.fields?.title}</span>
                            <Image image={adchoices?.fields?.image} noLazyLoad={true} />
                        </>
                    </ObLink>
                }
                {registerNavigationLabel && welcomeNavigationLink && welcomeNavigationLabel && logoutNavigationLabel &&
                    <>
                        {isObjectEmpty(userData) ? (
                            <>
                                {loginNavigationLabel && loginNavigationLink && <>
                                    <ObLink className='ob-login-navigation__link ob-login-navigation__link-login event_profile_login' dataActionDetail={loginNavigationLabel} href={loginNavigationLink}>
                                        {loginNavigationLabel}
                                    </ObLink>
                                    <span className='ob-login-navigation__spacer'>|</span>
                                </>}
                                <ObLink className='ob-login-navigation__link ob-login-navigation__link-register event_profile_register' dataActionDetail={registerNavigationLabel} href={registerNavigationLink}>
                                    {registerNavigationLabel}
                                </ObLink>
                            </>
                        ) : (
                            <>
                                <ObLink className='ob-login-navigation__link ob-login-navigation__link-login' href={welcomeNavigationLink}>
                                    <span>{welcomeNavigationLabel} {firstName}</span>
                                </ObLink>
                                <span className='ob-login-navigation__spacer'>|</span>
                                <button aria-label={logoutNavigationLabel} className='ob-login-navigation__link ob-login-navigation__link-register' onClick={logout}>
                                    {logoutNavigationLabel}
                                </button>
                            </>
                        )}
                    </>
                }
                {externalRegisterLink &&
                    <ObLink className='ob-login-navigation__link ob-login-navigation__link-register event_profile_register' dataActionDetail={externalRegisterLink?.fields?.title} href={externalRegisterLink?.fields?.url}>
                        {externalRegisterLink?.fields?.title}
                    </ObLink>
                }
            </div>
        </Suspense>
    )
}

LoginNavigationBlock.propTypes = {
    document: PropTypes.object
}
